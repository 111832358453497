import React, { useState, useEffect } from "react";
import WorkoutRoutineItem from "./WorkoutRoutineItem";
import Modal from "react-bootstrap/Modal";

const WorkoutRoutineList = ({
  routines,
  setAlert,
  workoutInput,
  updateWorkout,
  addWorkoutRoutine,
  handleRoutineUpdate,
  deleteWorkoutRoutine,
  locked,
  errors
}) => {
  //#region State management
  const [routine, setRoutine] = useState({
    step: 1,
    name: "",
    text: "",
    videoLink: "",
    lrVideoLink: "",
  });
  const [show, setShow] = useState(false);
  const [displayFrame, setDisplayFrame] = useState(false);
  const [routineContent, setRoutineContent] = useState(null);
  const [routinesUpdated, setRoutinesUpdated] = useState(false);

  //#endregion

  //#region Handlers
  const handleRoutine = (routine) => setRoutine(routine);
  const handleShow = () => setShow(true);

  // Custom iFrame handler object
  const iFrameHandler = {
    setFrame: (setFrame) => {
      return setDisplayFrame(setFrame);
    },
    setContent: (setContent) => {
      return setRoutineContent(setContent);
    },
    returnFrame: () => {
      return displayFrame;
    },
    returnContent: () => {
      return routineContent;
    },
  };

  const deleteRoutine = (routineId) => deleteWorkoutRoutine(routineId);
  //#endregion

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
        {/* <button 
          className={"btn btn-primary " + (locked ? "invisible" : "")}               
          onClick={handleSubmit}
        >
          <i className="fas fa-save"></i> {"Update"}
        </button> */}
        {/* <button
          className="btn btn-success"
          onClick={onAddClick}
          disabled={!(routines && routines.length < 14)}
        >
          Add
        </button> */}
      </div>
      {/* <AddWorkoutRoutine
        show={show}
        handleClose={handleClose}
        routine={routine}
        handleRoutine={handleRoutine}
        addRoutine={addRoutine}
        updateRoutine={updateRoutine}
        setAlert={setAlert}
        newStep={routine.step}
        routines={routines}
      /> */}
      <div className="table-responsive">
        <table className="table table-striped table-sm">
          <colgroup>
            <col style={{ width: "15%" }} />
            <col style={{ width: "15%" }} />
            <col style={{ width: "60%" }} />
            <col style={{ width: "10%" }} />
          </colgroup>
          <thead>
            <tr>
              <th>Name</th>
              {/* <th>Step</th> */}
              <th>Video Link</th>
              <th></th>
            </tr>
          </thead>
          {routines && (
            <tbody>
              {routines.map((routine) => (
                <WorkoutRoutineItem
                  key={routine._id}
                  routine={routine}
                  iFrameHandler={iFrameHandler}
                  handleRoutine={handleRoutine}
                  handleShow={handleShow}
                  deleteRoutine={deleteRoutine}
                  setAlert={setAlert}
                  locked={locked}
                  handleRoutineUpdate={handleRoutineUpdate}
                  error={errors.includes(routine._id)}
                />
              ))}
            </tbody>
          )}
        </table>
        {routinesUpdated && (
            <p
              className="alert alert-warning"
              role="alert"
              style={{
                padding: "10px",
                marginTop: "20px",
                textAlign: "center",
              }}
            >
              <b>You have unsaved changes!</b> Please click the Update button to
              save your changes.
            </p>
          )}
        {displayFrame && (
          <Modal show={displayFrame} backdrop="static" keyboard={false}>
            <Modal.Header
              style={{
                height: "3rem",
                padding: "1%",
                alignItems: "flex-start",
                marginLeft: "1%",
              }}
            >
              <span
                style={{
                  position: "relative",
                  textAlign: "center",
                  fontSize: "1.2rem",
                }}
              >
                NAME: {routineContent ? routineContent.name : null}
              </span>
              <button
                onClick={() => setDisplayFrame(false)}
                className="btn btn-danger btn-sm"
                style={{ right: "0" }}
              >
                X
              </button>
            </Modal.Header>
            {routineContent && routineContent.videoLink ? (
              <div style={{ textAlign: "center", marginTop: "-1.5%" }}>
                <div style={{ backgroundColor: "black" }}>
                  <video
                    height="495"
                    width="495"
                    src={routineContent.videoLink || null}
                    autoPlay={true}
                    loop={true}
                    muted={true}
                  ></video>
                </div>
              </div>
            ) : (
              ""
            )}
            {routineContent && routineContent.lrVideoLink ? (
              <div style={{ textAlign: "center", marginTop: "-25.5%" }}>
                <div style={{ backgroundColor: "black" }}>
                  <video
                    height="495"
                    width="495"
                    src={routineContent.lrVideoLink || null}
                    autoPlay={true}
                    loop={true}
                    muted={true}
                  ></video>
                </div>
              </div>
            ) : (
              ""
            )}
            <Modal.Footer style={{ justifyContent: "center", display: "flex" }}>
              <span
                style={{
                  padding: "1%",
                  display: "contents",
                  position: "relative",
                }}
              >
                {routineContent ? routineContent.text : null}
              </span>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </>
  );
};

export default WorkoutRoutineList;
