import React, { useState } from "react";

const WorkoutRoutineItem = ({
  routine,
  handleRoutine,
  handleShow,
  deleteRoutine,
  setAlert,
  iFrameHandler,
  locked,
  handleRoutineUpdate,
  error
}) => {

  const [routineVideoLink, setRoutineVideoLink] = useState(routine.videoLink);

  const onChange = (event) => {
    let value = null;

    value = event.target.value;
    
    setRoutineVideoLink(value);
    handleRoutineUpdate({
      ...routine,
      videoLink: value,
      updatedAt: Date.now()
    });
  };

  const handleIframe = () => {
    if (!iFrameHandler.returnFrame()) {
      iFrameHandler.setFrame(true);
      iFrameHandler.setContent(routine);
    } else {
      iFrameHandler.setFrame(false);
      iFrameHandler.setContent(null);
    }
  };

  return (
    <>
      {routine && (
        <tr>
          <td>{routine.name}</td>
          {/* <td>{routine.step}</td> */}
          <td>
            {(locked ? <a target={"blank"} href={routine.videoLink}>
              {routine.videoLink.substring(0, 150) + "..."}
              </a> :
              <input
                size="1"
                type="text"
                name="video-link"
                className={"form-control" + (error ? " is-invalid" : "")}
                title="Video Link"
                value={routineVideoLink || ""}
                placeholder="200 characters (max)"
                maxLength={200}
                onChange={onChange}
                disabled={locked}
                required={true}
              />
            )}
          </td>
          <td align="center">
            <div className="btn-group btn-group-sm text-end">
              <button
                className="btn btn-outline-dark"
                onClick={() => handleIframe()}
                disabled={!locked}
              >
                <i className="fas fa-eye"></i>
              </button>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default WorkoutRoutineItem;
